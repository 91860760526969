import { useState } from 'react';
import emailjs from '@emailjs/browser';
import styles from './ContactForm.module.css';
import useContactFormValidation from '../../hooks/useContactFormValidation';

const formFields = [
    { id: "name", label: "Name", type: "text" },
    { id: "email", label: "Email", type: "email" },
    { id: "message", label: "Message", type: "textarea" },
];

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    const { errors, isFormValid } = useContactFormValidation(formData);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const userId = process.env.REACT_APP_EMAILJS_USER_ID;

        emailjs.send(serviceId, templateId, formData, userId)
            .then((response) => {
                setResponseMessage("Message sent successfully!");
                setIsSubmitting(false);
                setTimeout(() => {
                    setResponseMessage("");
                    setFormData({ name: "", email: "", message: "" });
                }, 5000);
            }, (error) => {
                setResponseMessage("Failed to send message. Please try again later.");
                setIsSubmitting(false);
                setTimeout(() => {
                    setResponseMessage("");
                }, 5000);
            });
    };

    return (
        <form onSubmit={handleSubmit} className={styles.contactForm}>
            {formFields.map(field => (
                <div key={field.id} className={styles.formGroup}>
                    <div className={styles.labelWrapper}>
                        <label htmlFor={field.id} className={styles.label}>{field.label}</label>
                        {errors[field.id] && <span className={styles.error}>{errors[field.id]}</span>}
                    </div>
                    {field.type === "textarea" ? (
                        <textarea
                            id={field.id}
                            name={field.id}
                            value={formData[field.id]}
                            onChange={handleChange}
                            className={styles.inputField}
                            placeholder={`Enter your ${field.label.toLowerCase()}`}
                            style={{ height: '5rem' }}
                        />
                    ) : (
                        <input
                            type={field.type}
                            id={field.id}
                            name={field.id}
                            value={formData[field.id]}
                            onChange={handleChange}
                            className={styles.inputField}
                            placeholder={`Enter your ${field.label.toLowerCase()}`}
                        />
                    )}
                </div>
            ))}
            {responseMessage && <p className={styles.responseMessage}>{responseMessage}</p>}
            <button type="submit" disabled={isSubmitting || !isFormValid} className={styles.submitButton}>Send</button>
        </form>
    );
};

export default ContactForm;
