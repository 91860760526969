import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import FullScreenImage from "./components/FullScreenImage/FullScreenImage";
import useTitle from "./hooks/useTitle";
import "normalize.css";
import './App.css';

const pageTransition = {
  in: {
    opacity: 1,
    transition: {
      duration: 0.5
    }
  },
  out: {
    opacity: 0,
    transition: {
      duration: 0.5
    }
  }
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const background = location.state && location.state.background;

  useTitle();

  return (
    <>
      <Header />
      <AnimatePresence mode="wait">
        <Routes location={background || location} key={location.pathname}>
          <Route path="/" element={
            <div>
              <Home location={location} background={background} />
            </div>
          } />
          <Route path="/about" element={
            <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
              <About />
            </motion.div>
          } />
          <Route path="/contact" element={
            <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
              <Contact />
            </motion.div>
          } />
        </Routes>

        {background && (
          <Routes>
            <Route path="/image/:imageId" element={
              <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
                <FullScreenImage />
              </motion.div>
            } />
          </Routes>
        )}
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
