import styles from './Projects.module.css';

const projects = [
    {
        year: 2022,
        title: 'Sleep',
        description: 'ballet international gala performance feat. selfish sons',
        link: 'https://youtu.be/PaVDZOidFlc?si=PFGZS5djd9ZOpqAN'
    },
    {
        year: 2021,
        title: 'Her Coming In',
        description: 'music video for Danny Scott Lane directed and edited by Bren Cukier',
        link: 'https://vimeo.com/499237695'
    },
    {
        year: 2020,
        title: 'Sylvie Gulliem "A New Tomorrow"',
        description: 'short film in partnership with MDM Dancewear and Sylvie Guilliem',
        link: 'https://www.youtube.com/watch?v=HTIQxJwZDF8'
    },
    {
        year: 2020,
        title: 'Long Distance',
        description: 'intermission gala performance in partnership with Save the Children',
        link: 'https://www.youtube.com/watch?v=cuUjENdYLig'
    },
    {
        year: 2021,
        title: 'A Year of Sundays',
        description: 'award winning short film directed by Jérémie Dameme',
        link: 'https://www.imdb.com/title/tt15482994/?ref_=tt_mv_close'
    },
    {
        year: 2019,
        title: 'High Strung Free Dance',
        description: 'clip from feature film for Playbill',
        link: 'https://playbill.com/article/watch-a-steamy-clip-from-the-new-movie-high-strung-free-dance'
    },
    {
        year: 2018,
        title: 'Realistic City Living',
        description: 'official video for Beta Radio shot on 16mm',
        link: 'https://vimeo.com/291030100'
    },
    {
        year: 2017,
        title: 'Helium',
        description: 'performance at Australian Dance Festival',
        link: 'https://youtu.be/-E6FqtNtucc?si=b6HldaKT21qLXgoP'
    },
    {
        year: 2012,
        title: 'Be Great',
        description: 'Fifteen-year-old Juliet Doherty at TEDx Conference',
        link: 'https://www.youtube.com/watch?v=ShswlNMaUSA'
    }
];


const Projects = () => {
    return (
        <div className={styles.projectsContainer}>
            <h2 className={styles.projectsTitle}>RECENT PROJECTS</h2>
            <p className={styles.clickLink}>click for link</p>
            {projects.map((project, index) => (
                <div key={index} className={styles.projectItem}>
                    <h3 className={styles.projectYear}>{project.year}</h3>
                    <a href={project.link} target="_blank" rel="noopener noreferrer" className={styles.projectTitle}>
                        {project.title}
                    </a>
                    <p className={styles.projectDescription}>{project.description}</p>
                    {index < projects.length - 1 && <hr className={styles.divider} />}
                </div>
            ))}
        </div>
    );
};

export default Projects;
