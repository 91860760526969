import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({ src, alt, className }) => {
  return (
    <div>
      <LazyLoadImage
        alt={alt}
        src={src}
        className={className}
        effect="blur"
      />
    </div>
  );
};

export default LazyImage;
