import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faInstagram, faImdb } from "@fortawesome/free-brands-svg-icons";
import styles from "./Header.module.css";

const Header = () => {
  const location = useLocation();
  
  const navLinks = [
    { path: "/", label: "HOME" },
    { path: "/about", label: "ABOUT" },
    { path: "/contact", label: "CONTACT" },
  ];

  const socialLinks = [
    { href: "https://www.youtube.com/@julietdoherty3922", icon: faYoutube, label: "YouTube" },
    { href: "https://www.instagram.com/julietdoherty", icon: faInstagram, label: "Instagram" },
    { href: "https://www.imdb.com/name/nm5477182/?ref_=nv_sr_srsg_0_tt_2_nm_6_in_0_q_juliet%2520doh", icon: faImdb, label: "IMDb" },
  ];

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <ul className={styles.navList}>
          <div className={styles.socialIcons}>
            {socialLinks.map((link) => (
              <a
                key={link.href}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialLink}
                aria-label={link.label}
              >
                <FontAwesomeIcon icon={link.icon} />
              </a>
            ))}
          </div>
          {navLinks.map((link) => (
            <li key={link.path} className={styles.navItem}>
              <NavLink
                to={link.path}
                className={({ isActive }) =>
                  isActive || location.pathname === link.path
                    ? `${styles.navLink} ${styles.navLinkActive}`
                    : styles.navLink
                }
              >
                {link.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <h1 className={styles.headerTitle}>Juliet Doherty</h1>
    </header>
  );
};

export default Header;
