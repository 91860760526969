import styles from "./Contact.module.css";
import ContactForm from "../../components/ContactForm/ContactForm";

const Contact = () => {
    return (
        <div className={styles.contactContainer}>
            <h1 className={styles.heading}>GET IN TOUCH</h1>
            <ContactForm />
            <p className={styles.followInstagram}>
                Follow on <a className={styles.instagramLink} href="https://www.instagram.com/julietdoherty" target="_blank" rel="noopener noreferrer">Instagram</a>
            </p>
        </div>
    );
};

export default Contact;
