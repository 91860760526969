import juliet1 from "./juliet1.webp";
import juliet2 from "./juliet2.webp";
import juliet3 from "./juliet3.webp";
import juliet4 from "./juliet4.webp";
import juliet5 from "./juliet5.webp";
import juliet6 from "./juliet6.webp";
import juliet7 from "./juliet7.webp";
import juliet8 from "./juliet8.webp";
import juliet9 from "./juliet9.webp";
import juliet10 from "./juliet10.webp";
import juliet11 from "./juliet11.webp";
import juliet12 from "./juliet12.webp";
import juliet13 from "./juliet13.webp";
import juliet14 from "./juliet14.webp";
import juliet15 from "./juliet15.webp";
import juliet16 from "./juliet16.webp";
import juliet17 from "./juliet17.webp";
import juliet18 from "./juliet18.webp";
import juliet19 from "./juliet19.webp";
import juliet20 from "./juliet20.webp";
import juliet21 from "./juliet21.webp";
import juliet22 from "./juliet22.webp";
import juliet23 from "./juliet23.webp";
import juliet24 from "./juliet24.webp";
import juliet25 from "./juliet25.webp";
import juliet26 from "./juliet26.webp";
import juliet27 from "./juliet27.webp";
import juliet28 from "./juliet28.webp";
import juliet29 from "./juliet29.webp";

const images = [
  juliet1, juliet2, juliet3, juliet4, juliet5, juliet6,
  juliet7, juliet8, juliet9, juliet10, juliet11, juliet12,
  juliet13, juliet14, juliet15, juliet16, juliet17, juliet18,
  juliet19, juliet20, juliet21, juliet22, juliet23, juliet24,
  juliet25, juliet26, juliet27, juliet28, juliet29
];

export default images;
