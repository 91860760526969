import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useTitle() {
  const location = useLocation();

  useEffect(() => {
    const baseTitle = "Juliet Doherty";
    let title = baseTitle;

    switch (location.pathname) {
      case "/":
        title = "julietdoherty.com";
        break;
      case "/about":
        title = "A B O U T | " + baseTitle;
        break;
      case "/contact":
        title = "C O N T A C T | " + baseTitle;
        break;
      default:
        title = baseTitle;
        break;
    }

    document.title = title;
  }, [location]);
}

export default useTitle;
