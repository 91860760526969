import styles from './Footer.module.css'; 

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.footerContainer}>
      <p className={styles.footerText}>© {currentYear} Juliet Doherty. All rights reserved.</p>
    </div>
  );
};

export default Footer;
