import Masonry from 'react-masonry-css';
import { useNavigate, useLocation } from 'react-router-dom';
import LazyImage from '../LazyImage/LazyImage';
import styles from './ImageScroller.module.css';
import images from "../../images";

const breakpoints = {
  default: 3,
};

const ImageScroller = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleImageClick = (index) => {
    navigate(`/image/${index}`, { state: { background: location } });
  };

  return (
    <Masonry
      breakpointCols={breakpoints}
      className={styles.masonryGrid}
      columnClassName={styles.masonryGridColumn}
    >
      {images.map((image, index) => (
        <div key={index} className={styles.imageWrapper} onClick={() => handleImageClick(index)}>
          <LazyImage src={image} alt={`juliet${index + 1}`} className={styles.image} />
        </div>
      ))}
    </Masonry>
  );
};

export default ImageScroller;
