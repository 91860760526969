import julietBio from "../../images/julietBio.webp";
import Projects from "../../components/Projects/Projects";
import styles from "./About.module.css";

const aboutPageInfo = {
    bio: `Juliet Doherty is an American actress, guest artist, teacher, and choreographer. The New Mexico native was initially trained by her mother at Fishback Studio of the Dance, and continued her classical training at the San Francisco Ballet School. She is primarily known for her leading roles in Netflix feature films High Strung: Free Dance, Driven to Dance, and The Red Shoes: Next Step. 
Juliet is a two-time gold medalist of the Youth America Grand Prix International Finals, and has been a featured artist at the Kennedy Center, Lincoln Center, and Radio City Music Hall. She originated the role of “Ondine” in Marie, directed by Susan Stroman, and appeared in the role of “Lise” in the Tony award-winning musical An American in Paris. Additional accolades include prizes from the National Society of Arts & Letters, and the Beijing International Ballet Competition.
At the age of 15, Juliet became one of the youngest TEDx speakers. She spoke about empowering young artists and people alike to achieve their dreams through mindful practice. Juliet teaches and choreographs worldwide for pre-professional academies, companies, and universities. Juliet is a frequent guest artist, and co-creative collaborator with Ishida Dance Company, Ballet International Gala, and South Dakota Ballet Company. Most recently Juliet is featured in the upcoming action-thriller Ballerina, the fifth film in the John Wick franchise.`
};

const About = () => {
    return (
        <div className={styles.aboutContainer}>
            <h1 className={styles.aboutTitle}>BIO</h1>
            <img src={julietBio} alt="Juliet Doherty" className={styles.aboutImage} />
            <div className={styles.aboutText}>
                {aboutPageInfo.bio.split('\n').map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
            <Projects />
        </div>
    );
};

export default About;
