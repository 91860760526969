import { Routes, Route, useLocation } from "react-router-dom";
import ImageScroller from "../../components/ImageScroller/ImageScroller";
import FullScreenImage from "../../components/FullScreenImage/FullScreenImage";
import styles from './Home.module.css';

const Home = () => {
  const location = useLocation();
  const background = location.state && location.state.background;

  return (
    <div className={styles.homeContainer}>
      <Routes location={background || location}>
        <Route path="/" element={<ImageScroller />} />
      </Routes>

      {background && (
        <Routes>
          <Route path="/image/:imageId" element={<FullScreenImage />} />
        </Routes>
      )}
    </div>
  );
};

export default Home;
